import React, { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import AuthProvider from 'lib/context/AuthContext';
import SimpleBackdrop from 'components/mui/feedback/SimpleBackdrop';
import ErrorBoundary from 'components/requiero/ErrorBoundary';
import App from 'containers/App';
import theme from 'theme';
import configureAppStore from 'store/configureAppStore';
import 'setUpi18n';

const store = configureAppStore();

render(
  <Suspense fallback={<SimpleBackdrop open />}>
    <Router>
      <StrictMode>
        <ErrorBoundary>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Provider store={store}>
              <AuthProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </ThemeProvider>
              </AuthProvider>
            </Provider>
          </SnackbarProvider>
        </ErrorBoundary>
      </StrictMode>
    </Router>
  </Suspense>,
  document.querySelector('#root')
);
