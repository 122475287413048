import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import variables from 'styles/partials/_variables.module.scss';
import {
  MuiTab,
  MuiTabs,
  MuiGrid,
  MuiAppBar,
  MuiButton,
  MuiTextField,
  MuiFormLabel,
  MuiInputBase,
  MuiFormControl,
  MuiOutlinedInput,
} from 'theme/components';

console.log('variables.primaryColor', variables.primaryColor);

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: variables.primaryColor,
      dark: variables.primaryColorDark,
      light: variables.primaryColorLight,
    },
    secondary: {
      main: variables.secondaryColor,
      dark: variables.secondaryColorDark,
      light: variables.secondaryColorLight,
    },
    tertiary: {
      main: variables.tertiaryColor,
      light: variables.tertiaryColorLight,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiTab: MuiTab.overrides,
    MuiTabs: MuiTabs.overrides,
    MuiGrid: MuiGrid.overrides,
    MuiAppBar: MuiAppBar.overrides,
    MuiButton: MuiButton.overrides,
    MuiTextField: MuiTextField.overrides,
    MuiFormLabel: MuiFormLabel.overrides,
    MuiInputBase: MuiInputBase.overrides,
    MuiFormControl: MuiFormControl.overrides,
    MuiOutlinedInput: MuiOutlinedInput.overrides,
  },
  props: {
    MuiTabs: MuiTabs.props,
    MuiButton: MuiButton.props,
  },
  breakpoints: {
    values: {
      small: 370,
      mobile: 600,
      sm: 600,
      tablet: 960,
      md: 960,
      laptop: 1280,
      lg: 1280,
      desktop: 1920,
      xl: 1920,
    },
  },
});

export default theme;
