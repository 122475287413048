import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

import { useAuth } from 'lib/context/AuthContext';

const cache = new InMemoryCache({
  typePolicies: {
    StoreProduct: {
      fields: {
        active: {
          merge(prev, curr) {
            return curr ?? prev;
          },
        },
      },
    },
  },
});
const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_BASE_URL}/graphql` });

const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

export const useAppApolloClient = () => {
  const { authToken } = useAuth();

  return new ApolloClient({
    link: authMiddleware(authToken).concat(httpLink),
    cache,
  });
};
