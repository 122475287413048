const MuiTab = {
  overrides: {
    root: {
      fontWeight: 600,
      textTransform: 'capitalize',
    },
  },
};

export default MuiTab;
