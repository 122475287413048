import * as firebase from 'firebase/app';

import * as geofirex from 'geofirex';

import 'firebase/analytics';
import 'firebase/performance';

import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

import { config } from 'environments/environment';
import { config as configProd } from 'environments/environment.prod';

if (process.env.NODE_ENV === 'production') {
  firebase.initializeApp(configProd);
} else {
  firebase.initializeApp(config);
}

export default firebase;

export const geo = geofirex.init(firebase);

export const analytics = firebase.analytics();
export const performance = firebase.performance();

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
