import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'lib/context/AuthContext';
import { ROUTES } from 'components/requiero/Routes';
import SimpleBackdrop from 'components/mui/feedback/SimpleBackdrop';

export default function PrivateRoute({ component: C, ...rest }) {
  const { isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) return <SimpleBackdrop open />;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <C {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              state: {
                from: props.location,
                fromPrivate: props.location.pathname !== ROUTES.HOME_PAGE,
              },
            }}
          />
        )
      }
    />
  );
}
