import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  appRoot: {
    height: 'calc(100% - 64px)',
    margin: '64px 0 0 72px',
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 56px)',
      margin: '56px 0 0 0',
      padding: theme.spacing(2),
    },
  },
  appRootLoggedOut: {
    margin: '0',
    padding: '0',
    height: '100%',
  },
}));
