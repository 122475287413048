const MuiFormLabel = {
  overrides: {
    root: {
      // backgroundColor: '#ffffff',
      // padding: '2px',
      // borderRadius: '2px',
    },
  },
  props: {},
};

export default MuiFormLabel;
