import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'lib/context/AuthContext';

export default function UnauthenticatedRoute({ component: C, ...rest }) {
  const { isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) return null;

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <Redirect to={{ pathname: '/' }} /> : <C {...props} />)}
    />
  );
}
