const MuiTextField = {
  overrides: {
    root: {
      '& > .MuiFormHelperText-root': {
        margin: 0,
        marginTop: 5,
      },
    },
  },
};

export default MuiTextField;
