import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

import EmptyState from 'components/requiero/EmptyState';

class ErrorBoundary extends React.Component {
  state = { error: false };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    console.log(error);
    console.log(error.stack);
  }

  render() {
    return this.state.error ? (
      <EmptyState icon={<ErrorIcon />} title="Something went wrong" />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
