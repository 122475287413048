import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  list: {
    color: '#ffffff',
  },
  menuIcon: {
    display: 'none',
  },
});
