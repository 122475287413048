import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'red',
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    width: '90%',
  },
  list: {
    width: '100%',
    padding: '16px',
  },
  fullList: {
    width: 'auto',
    backgroundColor: 'red',
  },
  icon: {
    cursor: 'pointer',
    color: '#3FA28D',
    fontSize: '40px',
  },
  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
    icon: {
      fontSize: '34px',
      marginTop: theme.spacing(1),
    },
  },
  [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
    icon: {
      fontSize: '30px',
    },
  },
}));
