/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/mui/router/PrivateRoute';
import UnauthenticatedRoute from 'components/mui/router/UnauthenticatedRoute';
import asyncComponent from 'components/requiero/AsyncComponent';

/* eslint-disable react/display-name */
export const ROUTES = {
  HOME_PAGE: '/',
  USERS: '/users',
  PROFILE: '/profile',
  SEARCH: '/search', //  search playground
  WELCOME: '/welcome',
  STORE_PAGE: '/stores/:storeID',
  STORE_EDIT: '/stores/:storeID/edit',
  STORE_EDIT_PRODUCT: '/stores/:storeID/products/:productID/edit',
  STORE_PRODUCT: '/stores/:storeID/products/:productID',
  STORE_ADD_PRODUCT: '/stores/:storeID/products/add',
  STORE_PRODUCTS: '/stores/:storeID/products',
  STORE_CATEGORIES: '/stores/:storeID/categories',
  STORE_CATEGORY: '/stores/:storeID/categories/:categoryID',
  STORE_ADD_CATEGORY: '/stores/:storeID/categories/add',
  STORE_EDIT_CATEGORY: '/stores/:storeID/categories/:categoryID/edit',
  AUTH_ACTION: '/auth_action',
  LOGIN: '/login',
  SIGNUP: '/signup',
  LANDING_PAGE: '/landing-page',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_RECOVERY_FORM: '/password-recovery-form',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  FAQ: '/faq',
  // admin views - TODO: maybe create a secured website for this type of stuff
  REQUIERO_PRODUCT_ADD: '/requiero-product/add',
  REQUIERO_PRODUCT_EDIT: '/requiero-product/:requieroProductID',
};

const LazyHomePage = asyncComponent(() => import('containers/HomePage'), 300);
const LazyUsersPage = asyncComponent(() => import('containers/UsersPage'), 300);
const LazySearchPage = asyncComponent(() => import('containers/SearchPage'), 300);
const LazyProfilePage = asyncComponent(() => import('containers/ProfilePage'), 300);
const LazyWelcomePage = asyncComponent(() => import('containers/WelcomePage'), 300);
const LazyStoreManagementPage = asyncComponent(() => import('containers/StorePageManagement'), 300);

const LazyLoginPage = asyncComponent(() => import('containers/LoginPage'), 300);
const LazySignUpPage = asyncComponent(() => import('containers/SignUpPage'), 300);
const LazyPasswordRecoveryPage = asyncComponent(
  () => import('containers/PasswordRecoveryPage'),
  300
);
const LazyPasswordRecoveryFormPage = asyncComponent(
  () => import('containers/PasswordRecoveryForm'),
  300
);

const LazyLandingPage = asyncComponent(() => import('containers/LandingPage'), 300);
const LazyTermsAndConditions = asyncComponent(() => import('containers/TermsAndConditions'), 300);
const LazyFaq = asyncComponent(() => import('containers/Faq'), 300);
const LazyAuthActionPage = asyncComponent(() => import('containers/AuthActionPage'), 300);

const LazyRequieroProductFormPage = asyncComponent(
  () => import('containers/RequieroProductFormPage'),
  300
);

function Routes() {
  return (
    <Switch>
      {/* Private Routes */}
      <PrivateRoute exact path={ROUTES.HOME_PAGE} component={LazyHomePage} />
      <PrivateRoute exact path={ROUTES.USERS} component={LazyUsersPage} />
      <PrivateRoute exact path={ROUTES.SEARCH} component={LazySearchPage} />
      <PrivateRoute exact path={ROUTES.PROFILE} component={LazyProfilePage} />
      <PrivateRoute exact path={ROUTES.WELCOME} component={LazyWelcomePage} />
      <PrivateRoute path={ROUTES.STORE_PAGE} component={LazyStoreManagementPage} />
      <PrivateRoute
        path={[ROUTES.REQUIERO_PRODUCT_ADD, ROUTES.REQUIERO_PRODUCT_EDIT]}
        component={LazyRequieroProductFormPage}
      />

      {/* Unauthenticated Routes */}
      <UnauthenticatedRoute path={ROUTES.LOGIN} component={LazyLoginPage} />
      <UnauthenticatedRoute path={ROUTES.SIGNUP} component={LazySignUpPage} />
      <UnauthenticatedRoute path={ROUTES.PASSWORD_RECOVERY} component={LazyPasswordRecoveryPage} />
      <UnauthenticatedRoute
        path={ROUTES.PASSWORD_RECOVERY_FORM}
        component={LazyPasswordRecoveryFormPage}
      />

      {/* Public Routes */}
      <Route path={ROUTES.LANDING_PAGE} component={LazyLandingPage} />
      <Route path={ROUTES.TERMS_AND_CONDITIONS} component={LazyTermsAndConditions} />
      <Route path={ROUTES.FAQ} component={LazyFaq} />
      <Route path={ROUTES.AUTH_ACTION} component={LazyAuthActionPage} />

      <Route path="*" component={NoMatch} />
    </Switch>
  );
}

export default memo(Routes);

// todo: create 404 page
function NoMatch() {
  return <h3>NoMatch</h3>;
}
