import React, { useCallback } from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Icon from '@material-ui/core/Icon';

import { useStyles } from './MenuSwipeableDrawer.styles';

export default function MenuSwipeableDrawer(props) {
  const classes = useStyles();
  const { open: propsOpen = false, onClose, anchor = 'left' } = props;
  const [open, setOpen] = React.useState(propsOpen);

  const handleToggleDrawer = useCallback(
    (open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setOpen(open);
      if (!open && onClose) onClose();
    },
    [onClose]
  );

  return (
    <>
      <Icon onClick={handleToggleDrawer(true)} className={clsx(classes.icon, props.iconClassName)}>
        menu
      </Icon>
      <SwipeableDrawer
        open={open || propsOpen}
        anchor={anchor}
        classes={{ paper: classes.paper }}
        onOpen={handleToggleDrawer(true)}
        onClose={handleToggleDrawer(false)}
      >
        <div
          role="presentation"
          onClick={handleToggleDrawer(false)}
          onKeyDown={handleToggleDrawer(false)}
          className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
          })}
        >
          {props.children}
        </div>
      </SwipeableDrawer>
    </>
  );
}
