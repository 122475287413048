const MuiOutlinedInput = {
  overrides: {
    root: {
      borderRadius: '2px',
      '&:hover > .MuiOutlinedInput-notchedOutline': {
        borderColor: '#CCCCCC',
      },
    },
    input: {
      // min font-size for inputs do not override
      // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone#:~:text=Additionally%2C%20the%20select%20element%20needs,the%20focus%20pseudo%2Dclass%20attached.&text=You%20can%20prevent%20Safari%20from,attribute%20suggested%20in%20other%20answers.
      fontSize: '16px!important',
    },
    notchedOutline: {
      borderColor: '#FDF0EA',
    },
  },
};

export default MuiOutlinedInput;
