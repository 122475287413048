import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { fetchSomething, fetchSomethingSuccess, fetchSomethingError } from 'store/reducers/common';

export const fetchSomethingEpic = (action$) =>
  action$.pipe(
    ofType(fetchSomething.type),
    mergeMap((action) =>
      from(
        new Promise((resolve) => {
          setTimeout(() => resolve(action.payload), 1000);
        })
          .then(fetchSomethingSuccess)
          .catch(fetchSomethingError)
      )
    )
  );
