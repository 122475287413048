import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  stepsMap: {},
};

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    updateStep(state, action) {
      const stepIndex = action.payload.index;
      const step = { ...(state.stepsMap[stepIndex] || {}), ...action.payload };
      state.stepsMap[stepIndex] = step;
    },
  },
});

export const { updateStep } = welcomeSlice.actions;

export default welcomeSlice.reducer;
