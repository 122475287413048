import React from 'react';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { ROUTES } from 'components/requiero/Routes';
import MenuSwipeableDrawer from 'components/mui/navigation/MenuSwipeableDrawer';
import { useStyles } from './MenuDrawerApp.styles';

export default function MenuDrawerApp(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <MenuSwipeableDrawer iconClassName={classes.menuIcon} {...props}>
      <List className={classes.list}>
        <ListItem button onClick={() => history.push(ROUTES.HOME_PAGE)}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => history.push(ROUTES.USERS)}>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
    </MenuSwipeableDrawer>
  );
}
