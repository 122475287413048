import React, { lazy, Suspense } from 'react';
import SimpleBackdrop from 'components/mui/feedback/SimpleBackdrop';

export default function asyncComponent(importComponent, delay = 300) {
  const LazyComponent = lazy(importComponent);

  // eslint-disable-next-line react/display-name
  return (props) => {
    return (
      <Suspense fallback={<SimpleBackdrop delay={delay} />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
}
