import React, { memo, useState, useEffect, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import Routes, { ROUTES } from 'components/requiero/Routes';
import TopAppBar from 'components/mui/surfaces/TopAppBar';
import SimpleBackdrop from 'components/mui/feedback/SimpleBackdrop';
import { auth } from 'lib/firebase';
import { useAuth } from 'lib/context/AuthContext';
import { useAppApolloClient } from 'lib/graphql';
import { useStyles } from './App.styles';
import 'styles/application.scss';

function App(props) {
  const { isLoading } = props;

  const { user } = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation();
  const client = useAppApolloClient();
  const [isLanding, setIsLanding] = useState(false);
  const { lang } = user || {};

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      auth.lang = lang;
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    setIsLanding(
      location.pathname.includes(ROUTES.LANDING_PAGE) ||
        location.pathname.includes(ROUTES.TERMS_AND_CONDITIONS) ||
        location.pathname.includes(ROUTES.FAQ)
    );
  }, [location.pathname]);

  const renderTopBar = useMemo(() => {
    return isLanding ? null : <TopAppBar />;
  }, [isLanding]);

  const showBackdrop = !!isLoading;
  return (
    <ApolloProvider client={client}>
      <div
        className={clsx(classes.appRoot, {
          [classes.appRootLoggedOut]: isLanding,
        })}
      >
        {renderTopBar}
        <Routes />
        {<SimpleBackdrop open={showBackdrop} />}
      </div>
    </ApolloProvider>
  );
}

export default memo(App);
