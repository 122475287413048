const MuiButton = {
  overrides: {
    root: {
      borderRadius: '2px',
    },
    label: {
      fontWeight: 600,
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
    },
  },
  props: {
    color: 'primary',
    variant: 'contained',
  },
};

export default MuiButton;
