import i18n from 'i18next';
import XHR from 'i18next-xhr-backend'; // deprecated use: i18next-http-backend
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'es-AR',

    // have a common namespace used around the full app
    ns: 'translation',
    defaultNS: 'translation',

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    // keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      // https://www.i18next.com/translation-function/formatting
      format: function (value) {
        // if (format === 'uppercase') return value.toUpperCase();
        // if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
