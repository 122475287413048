export const config = {
  apiKey: 'AIzaSyAGUV4HoQ4YZET55EszI9daBSl-kj06aPk',
  authDomain: 'requieroapp.com',
  databaseURL: 'https://requiero-7a3eb.firebaseio.com',
  projectId: 'requiero-7a3eb',
  storageBucket: 'requiero-7a3eb.appspot.com',
  messagingSenderId: '551974110259',
  appId: '1:551974110259:web:e95a4b0964b713ee2a8f46',
  measurementId: 'G-3G5SQ918SP',
};
