import React, { memo, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';

import { useAuth } from 'lib/context/AuthContext';
import { ROUTES } from 'components/requiero/Routes';
import LeftDrawer from 'components/mui/navigation/LeftDrawer';
import MenuDrawerApp from './elements/MenuDrawerApp';
import { useStyles } from './TopAppBar.styles';

function TopAppBar() {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { handleLogout, isAuthenticated } = useAuth();

  const [navOpen, setNavOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    setNavOpen(true);
  };

  const handleDrawerClose = () => {
    setNavOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleGoToProfile = () => {
    history.push(ROUTES.PROFILE);
    handleMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleGoToProfile}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem
        onClick={() => {
          handleLogout();
          handleMenuClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      keepMounted
      id={mobileMenuId}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const renderNotAuthedButtons = useMemo(
    () => (
      <>
        <Button color="primary" component={Link} to="/login" className={classes.loginButton}>
          Login
        </Button>
        <Button color="secondary" component={Link} to="/signup">
          Register
        </Button>
      </>
    ),
    [classes.loginButton]
  );

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: navOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: navOpen || !isAuthenticated,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Requiero
          </Typography>
          <div className={classes.sectionDesktop}>
            {isAuthenticated ? (
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            ) : (
              renderNotAuthedButtons
            )}
          </div>
          <div className={classes.sectionMobile}>
            {isAuthenticated ? (
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            ) : (
              renderNotAuthedButtons
            )}
          </div>
        </Toolbar>
      </AppBar>
      {smallScreen ? (
        <MenuDrawerApp open={navOpen} onClose={handleDrawerClose} />
      ) : (
        <LeftDrawer
          open={navOpen}
          isAuthenticated={isAuthenticated}
          handleDrawerClose={handleDrawerClose}
        />
      )}
      {renderMobileMenu}
      {renderMenu}
    </>
  );
}

export default memo(TopAppBar);
