import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import firebase, { auth, firestore } from 'lib/firebase';
import { ROUTES } from 'components/requiero/Routes';

interface AuthContextValue {
  user: any;
  authToken: string | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  handleLogin: (user: any) => void;
  handleLogout: () => void;
  updateAuthUser: (user: any) => void;
}

const AuthContext = React.createContext<AuthContextValue>({
  user: null,
  authToken: null,
  isAuthenticated: false,
  isAuthenticating: false,
  handleLogin: () => {},
  handleLogout: () => {},
  updateAuthUser: () => {},
});

function useAuth() {
  return React.useContext(AuthContext);
}

let userDocSnapshotUnsubscribe: any = null;

function AuthProvider({ children }: React.PropsWithChildren<any>) {
  const history = useHistory<any>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = React.useState<any>(null);
  const [authToken, setAuthToken] = React.useState(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);

  const from = history.location?.state?.from;

  const handleLogin = React.useCallback((user) => {
    setUser(user);
    setIsAuthenticated(true);
    setIsAuthenticating(false);
  }, []);

  const handleLogout = React.useCallback(() => {
    userDocSnapshotUnsubscribe && userDocSnapshotUnsubscribe();
    auth.signOut();
    setIsAuthenticated(false);
    setIsAuthenticating(false);
  }, []);

  const handleError = React.useCallback(
    (error) => {
      alert(error);
      handleLogout();
    },
    [handleLogout]
  );

  const handleSnapshot = React.useCallback(
    (user) =>
      async (snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        try {
          let userData = snapshot.data();
          // if the use has more than one provider get data from social
          let providerData = user.providerData.find(
            ({ providerId }: any) => providerId !== 'password'
          );

          if (!providerData) {
            providerData = user.providerData[0];
          }

          const { uid, providerId, displayName, photoURL, ...rest } = providerData;

          if (!snapshot.exists || !userData) {
            if (providerId === 'password') {
              throw new Error('User not found.');
            }

            const reference = firestore.collection('users').doc(user.uid);

            userData = (await reference.set({ displayName, photoURL }, { merge: true })) as any;

            const privateDataReference = reference.collection('private_data').doc('private');

            await privateDataReference.set({ ...rest }, { merge: true });

            history.push(ROUTES.WELCOME);

            return;
          }

          userData.uid = user.uid;
          userData.email = user.email;
          userData.photoURL = user.photoURL;
          userData.emailVerified = user.emailVerified;

          isAuthenticated && isAuthenticating ? setUser(userData) : handleLogin(userData);
        } catch (error: any) {
          console.log(error);
          console.log(error.stack);

          enqueueSnackbar(t('default_error_message'));

          handleLogout();
        }
      },
    [t, isAuthenticated, isAuthenticating, history, enqueueSnackbar, handleLogin, handleLogout]
  );

  const handleAuthStateChanged = React.useCallback(
    (user: firebase.User | null) => {
      const authToken = (user?.toJSON?.() as any)?.stsTokenManager?.accessToken;
      setAuthToken(authToken);

      if (!user || !user.uid) {
        handleLogout();
        return;
      }

      userDocSnapshotUnsubscribe = firestore
        .collection('users')
        .doc(user.uid)
        .onSnapshot(handleSnapshot(user), handleError);
    },
    [handleError, handleLogout, handleSnapshot]
  );

  const handleRedirectResult = React.useCallback(
    (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      if (result.credential) {
        history.push(ROUTES.HOME_PAGE);
      }
    },
    [history]
  );

  const handleRedirectError = React.useCallback(
    (error = {}) => {
      // The email of the user's account used. error.email;
      // The firebase.auth.AuthCredential type that was used. error.credential;
      const code = error.code;
      const message = error.message;

      switch (code) {
        case 'auth/timeout':
        case 'auth/account-exists-with-different-credential':
        case 'auth/auth-domain-config-required':
        case 'auth/credential-already-in-use':
        case 'auth/email-already-in-use':
        case 'auth/operation-not-allowed':
        case 'auth/operation-not-supported-in-this-environment':
          enqueueSnackbar(message, { variant: 'error' });
          break;

        default:
          enqueueSnackbar(message || t('default_error_message'));
          break;
      }
    },
    [t, enqueueSnackbar]
  );

  React.useEffect(() => {
    // todo: check if from.pathname is valid path (part of our ROUTES)
    from && isAuthenticated && history.push(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  React.useEffect(() => {
    const subscriber = auth.onAuthStateChanged(handleAuthStateChanged, handleError);

    auth.getRedirectResult().then(handleRedirectResult).catch(handleRedirectError);

    return subscriber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update user data in auth context
  const updateAuthUser = React.useCallback((data) => {
    setUser((user: any) => ({ ...(user || {}), ...data }));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        authToken,
        isAuthenticated,
        isAuthenticating,
        handleLogin,
        handleLogout,
        updateAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element,
};

export { useAuth };

export default React.memo(AuthProvider);
