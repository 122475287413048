import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  error: null,
  isLoading: false,
  isWhiteLogo: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchSomething(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchSomethingSuccess(state, action) {
      state.something = action.payload;
      state.isLoading = false;
    },
    fetchSomethingError(state, action) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    setWhiteLogo(state, action) {
      state.isWhiteLogo = action.payload;
    },
  },
});

export const {
  setIsLoading,
  fetchSomething,
  fetchSomethingSuccess,
  fetchSomethingError,
  setWhiteLogo,
} = commonSlice.actions;

export default commonSlice.reducer;
